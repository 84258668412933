import React from "react"
import { graphql } from "gatsby"
import Styles from "../styles/impressum.module.css"
import HeaderMenu from "../components/header/top-header-with-menu/topHeaderWithMenu"
import Footer from "../components/footer/footer"
import { Helmet } from "react-helmet"
import CookieConsent from "../components/cookie-consent";

const Impressum = ({data , location}) => {
  const cookieConsentRef = React.createRef();
  return (
    <div >
      <CookieConsent locale="de" visibilityRef={cookieConsentRef} />
      <HeaderMenu url = {location.href} page="impressum" />
      <Helmet htmlAttributes={{ lang: "de" }}>
        <title>{data.datoCmsSeiten.seo.title}</title>
        <meta name="description" content={data.datoCmsSeiten.seo.description} />
      </Helmet>
      <header className={Styles.sectionContainer}>
        <h1 className={Styles.h1}>{data.datoCmsSeiten.header[0] ? data.datoCmsSeiten.header[0].headline : ''}</h1>
        <div
          dangerouslySetInnerHTML={{
            __html: data.datoCmsSeiten.header[0] ? data.datoCmsSeiten.header[0].subheadline : ''
          }}
        ></div>
      </header>
      <main className={`${Styles.sectionContainer} ${Styles.main}`}>
        {data.datoCmsSeiten.content.map((item, index) => {
          return (
            <div key={index}>
              <h4>{item.quoteHeadline}</h4>
              <div
              key={index}
              dangerouslySetInnerHTML={{
                __html: item.text,
                }}
              ></div>
            </div>
          )
        })}
      </main>
      <Footer cookieConsentRef={cookieConsentRef} />
    </div>
  )
}

export default Impressum

export const query = graphql`
  query impressum {
    datoCmsSeiten(name: {eq: "Impressum"}) {
      seo {
        description
        title
        twitterCard
      }
      id
      slug
      header {
        ... on DatoCmsHeaderBlueCross {
          id
          headline
          subheadline
        }
      }
      content {
        ... on DatoCmsText {
          id
          quoteHeadline
          text
        }
      }
    }
  }
`
